import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import AddressInput from './dealer-search/address-input'
import MapBox from './dealer-search/map-box'
import {
	dealerTypes,
	useDealerSearch,
	useAddressSearch,
} from './dealer-search/utils'

const FindDealerSection = styled.section`
	min-height: 900px;
	background: url('${({ bg }) => (bg ? bg : '')}');
	background-color: #c5cbc0;
	${tw`w-full bg-cover mb-4 md:mb-0 bg-no-repeat bg-center pb-8`}
`
const FindDealerContainer = styled.div`
	${tw`flex flex-col w-full h-full pt-4 px-2 md:px-0 lg:pt-12 items-center md:justify-start lg:justify-center`}
	min-height: 900px;
`
const FindDealerContents = tw.div`flex flex-col w-full xxxl:w-9/12 mx-auto items-center justify-center`
const Title = styled.h2`
	@media (min-width: 525px) {
		${tw`text-6xl`}
	}
	text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
	${tw`font-display text-white text-5xl md:text-7xl font-bold capitalize mb-6`}
`
const Subtitle = styled.p`
	${tw`font-display text-white text-xl md:w-7/12 xl:w-5/12 xxl:w-1/3 mx-auto text-center`}
	text-shadow: 3px 3px 8px rgba(0,0,0,.75);
`

const FindDealer = ({ finddealer }) => {
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Subtitle>{children}</Subtitle>,
		},
	}

	const { hook, description, backgroundImage } = finddealer

	const [queryAddress, onQueryAddress] = useState('')
	const [selectAddress, onSelectAddress] = useState({
		name: '',
		coordinates: [],
	})
	const [selectType, onSelectType] = useState(dealerTypes[0])
	const addresses = useAddressSearch(queryAddress)
	const [dealer, setDealer] = useState({})
	const [selectDealer, onSelectDealer] = useState(0)
	const handleSelectDealer = i => {
		setDealer(dealers[i])
		onSelectDealer(i)
	}
	const { dealers, loading } = useDealerSearch(
		selectType.type,
		selectAddress.coordinates,
		setDealer
	)

	return (
		<FindDealerSection bg={backgroundImage.file.url}>
			<FindDealerContainer>
				<FindDealerContents>
					<Title>{hook}</Title>
					{documentToReactComponents(description.json, options)}
					<div tw="w-full inline-block mt-4">
						{dealer && dealer.name && (
							<div tw="flex items-center justify-center mb-4">
								<div tw="w-full max-w-sm mx-auto">
									<div tw="inline-block w-full rounded-md shadow-sm">
										<div tw="cursor-default relative flex flex-col space-y-1 w-full rounded-md border border-gray-300 bg-white p-2 text-left sm:text-sm sm:leading-5">
											<img
												id="dealer-logo"
												tw="w-auto mx-auto mb-2 object-contain max-h-[100px]"
												alt={dealer.name}
												src={dealer.logo}
											/>
											<h3 tw="text-center mb-2 font-display font-bold text-base text-gray-700">
												{dealer.name}
											</h3>
											<h4 tw="text-center mb-2 font-display text-sm text-gray-700">
												{`${dealer.address.city} ${dealer.address.postalCode}`}
											</h4>
											<a
												tw="text-center mb-2 font-display font-bold text-sm text-blue-500 leading-6"
												href={'tel:' + dealer.phone}
											>
												{dealer.phone}
											</a>
											<div
												className="button-group"
												tw="inline-flex space-x-2 mx-auto"
											>
												<a
													tw="rounded-md px-3 py-2 bg-yellow-400 text-gray-600 font-bold focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 transition ease-in-out duration-150 "
													href={`/shop/?ref=${dealer.affiliateId}`}
													target="_blank"
													rel="noreferrer noopener"
												>
													Shop
												</a>
												<a
													tw="rounded-md px-3 py-2 bg-blue-400 text-gray-600 font-bold focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
													href="/book-a-demo/"
													target="_blank"
													rel="noreferrer noopener"
												>
													Book a Demo
												</a>
												{Boolean(dealer.type.indexOf('elite') + 1) && (
													<a
														tw="rounded-md px-3 py-2 border border-gray-300 text-gray-600 font-bold focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
														href={`https://mydolphin.com.au/pool-cleaners-${
															dealer.social.minisite
														}?ref=${
															dealer.affiliateId
														}&email=${dealer.email.toString()}`}
														target="_blank"
														rel="noreferrer noopener"
													>
														Profile
													</a>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<AddressInput
							{...{
								hideLabel: true,
								queryAddress,
								onQueryAddress,
								addresses,
								selectAddress,
								onSelectAddress,
							}}
						/>
						<MapBox
							{...{
								loading,
								selectAddress,
								selectType,
								dealers,
								selectDealer,
								handleSelectDealer,
							}}
						/>
					</div>
				</FindDealerContents>
			</FindDealerContainer>
		</FindDealerSection>
	)
}
FindDealer.propTypes = {
	finddealer: PropTypes.shape({
		hook: PropTypes.string,
		description: PropTypes.object,
		backgroundImage: PropTypes.object,
	}),
}
export default FindDealer
